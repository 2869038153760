body {
    font-family: 'Open Sans', sans-serif;
}

.btn-actions {
    font-size: 11px;
    background-color: #009845 !important;
    background-image: none !important;
    border-radius: 3px;
}

.btn-actions:hover, .btn-actions:active, .btn-actions:focus {
    background-color: #007434 !important;
}

.btn-admin-primary {
    font-size: 11px;
    background-color: #009845 !important;
    background-image: none !important;
    color: #FFF !important;
    border-width: 0px;
    border-radius: 3px;
}

.btn-admin-primary:hover, .btn-admin-primary:active, .btn-admin-primary:focus {
    background-color: #007434 !important;
}

.btn-admin-secondary {
    font-size: 11px;
    background-color: #FF7800 !important;
    background-image: none !important;
    color: #FFF !important;
    border-width: 0px;
    border-radius: 3px;
}

.btn-admin-secondary:hover, .btn-admin-secondary:active, .btn-admin-secondary:focus {
    background-color: #db6600 !important;
}

.btn-logout {
    font-size: 11px;
    color: #FFF !important;
    border-color: #FFF !important;
    border-width: 1 !important;
    border-radius: 3px !important;
    background: none;
    background-image: none !important;
}

.btn-logout:hover, .btn-logout:active, .btn-logout:focus {
    background-color: #007434 !important;
}

.btn-admin-secondary:focus, .input:focus {
    box-shadow: inset 0 1px 0 rgba(255,255,255,.15),0 1px 1px rgba(0,0,0,.075),0 0 0 .1rem rgba(255, 123, 0, 0.5) !important;
}

.table-admin-sort-header {
    font-size: 11px;
    font-weight: 600;
    cursor: pointer;
}

.table-admin-header {
    font-size: 11px;
    font-weight: 600;
}

.td-admin {
    font-size: 11px;
}

.modal-address {
    max-width: none!important;
    width: 70%;
    font-size: 11px;
}

.modal-install-complete {
    max-width: none!important;
    width: 50%;
    font-size: 11px;
}

.table-responsive {
    overflow: visible;
}

.modal .form-control, .modal .form-select {
    font-size: 11px;
}

.modal-alert-header {
    padding: 0.5rem;
    background-color: #c52e2c;
}

.modal-alert-header {
    padding: 0.5rem;
    background-color: #c52e2c;
}

.modal-admin-header {    
    padding: 0.5rem;
    background-color: #009845;
}

.modal-admin-heading {
    font-size: 13px;
    color: #FFF;
}

.nav-link {
    color: #000;
    font-size: 13px;
}

.nav-link.active {
    color: #FFF !important;
    background-color: #009845 !important;
}

.nav-link:hover, .nav-link:active, .nav-link:focus {
    color: #009845;
}

.search-box {
    width: 100%;
    font-size: 11px;
    border-radius: 3px;
    border: 1px solid #ccc;
}

.bs-tooltip-top {
    font-size: 11px;
    font-family: 'Open Sans', sans-serif;
}

.half-column {
    flex: 0 0 4.16667%;
    max-width: 3em;
}

.eleven-half-column {
    flex: 0 0 95.83333%;
    max-width: 95.83333%;
}

.offset-half {
    margin-left: 3em;
}

.text-medium {
    color: #555 !important;
}

.form-check-input:checked {
    background-color: #009845 !important;
    border-color: #009845 !important;
}

.icon-disabled {
	color: #00984485;
}

.sidebar {
    font-size: 12px;
}